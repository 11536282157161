import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { SearchBox } from 'react-instantsearch-dom';

const query = graphql`
  {
    file(relativePath: { eq: "hero-posts.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

const Hero = () => {
  const { file } = useStaticQuery(query);
  return (
    <div className="flex flex-col">
      <div className="pt-6 md:pt-8">
        <div className="flex flex-wrap items-center pb-12">
          <div className="lg:w-1/2 w-full">
            <div className="ml-2 lg:ml-24">
              <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">
                Encuentra la Información más Relevante acerca de la Diabetes
              </h2>
              <p className="mb-8 sm:text-xl leading-relaxed">
                Nuestro equipo de profesionales te brinda importante información
                en estos artículos.
              </p>

              {/* Forma de Envío */}
              <SearchBox className="border w-5/6" />
            </div>
          </div>
          <Wrapper className="hidden lg:flex md:w-1/2 w-full relative h-96 justify-center">
            <div className="col1" />
            <div className="col2">
              <GatsbyImage
                image={file.childImageSharp.gatsbyImageData}
                alt="hero image"
                className="image"
              />
            </div>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};

export default Hero;

const Wrapper = styled.div`
  .col1 {
    background: white;
    width: 10%;
  }
  .col2 {
    background: #f0f4f7;
  }
  .image {
    margin-left: -6rem;
  }

  /* clip-path: polygon(31% 0, 100% 0, 100% 100%, 14% 100%); */
`;
