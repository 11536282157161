import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import Top5Recipes from '../components/Top5Recipes';
import Hero from '../components/Posts/Hero';
import { orderBy } from 'lodash';

import {
  InstantSearch,
  Hits,
  Highlight,
  Stats,
  Pagination,
  RefinementList,
} from 'react-instantsearch-dom';

import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

//** The good documentation for this is in
// https://github.com/typesense/typesense-instantsearch-adapter

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: 'NH7eqMFZ7dRku6Ij4eT3YThhQYmwxm8l', // Be sure to use the search-only-api-key
    nodes: [
      {
        host: 'search.dediabetes.com',
        port: '',
        protocol: 'https',
      },
    ],
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  queryBy is required.
  additionalSearchParameters: {
    queryBy: 'title,description',
    filterBy: 'type: articulos',
    perPage: 8,
    //sortBy: 'name:asc',
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

function Hit(props) {
  const link = {
    tag: props.hit.tag_article,
    date: props.hit.article_date,
    author: props.hit.article_author,
    authorImage: props.hit.article_author_image,
    title: <Highlight attribute="title" hit={props.hit} />,
    plainTitle: props.hit.title,
    description: <Highlight attribute="description" hit={props.hit} />,
    url: props.hit.page_path,
    image: props.hit.image,
    sourceName: props.hit.advice_source_n,
    sourceDescription: props.hit.advice_source_d,
    referenceUrl: props.hit.advice_ref_url,
  };
  return <LinkCard link={link} />;
}

const LinkCard = ({ link }) => {
  const description = link.description.props.hit.description.substring(0, 260);

  return (
    <div className="">
      <div className="flex gap-2 mb-3">
        {link.tag.map(item => {
          if (item !== '') {
            return (
              <div
                key={item}
                className="border border-themeBrandColor rounded hover:bg-themeBrandColor text-themeBrandColor hover:text-white px-2 mr-3 whitespace-nowrap"
              >
                {item}
              </div>
            );
          } else return null;
        })}
        {link.date}
      </div>

      <img
        className="w-100"
        src={link.image}
        alt={link.plainTitle}
        title={link.plainTitle}
      />
      <Link to={link.url}>
        <h2 className="hover:text-themeBrandColor my-4 text-xl leading-none font-headings">
          {link.title}
        </h2>
        <p>{description} ...</p>
      </Link>
      <div className="flex gap-1 items-center font-headings">
        <div
          className="border-4 border-themeBrandColor rounded-full overflow-hidden shadow-inner mr-2"
          style={{ width: '48px', height: '48px', minWidth: '48px' }}
        >
          <img
            src={link.authorImage}
            alt={link.author}
            className="object-cover object-center w-full h-full"
          />
        </div>
        {link.author}
      </div>
    </div>
  );
};

const Advises = () => {
  return (
    <Layout>
      <Wrapper>
        <InstantSearch searchClient={searchClient} indexName="pages_v1">
          <Hero />
          <div className="container mx-auto">
            <div className="ml-2 lg:ml-24 text-themeDark">
              <RefinementList
                attribute="tag_article"
                limit={8}
                showMore
                transformItems={items => orderBy(items, 'label', 'asc')}
              />
            </div>
          </div>
          <div className="grid grid-cols-blog w-full container mx-auto gap-x-4 p-2">
            <div className="col-span-2 md:col-span-1 mx-autoleading-losse my-6 xl:px-24">
              <div className="flex gap-4 flex-col-reverse sm:flex-row">
                <div className="left px-2">
                  <Hits hitComponent={Hit} className="list-none" />
                  <Pagination hitsPerPage={3} />
                  <Stats />
                </div>
              </div>
            </div>

            {/* Side Bar */}
            <aside className=" hidden md:block md:visible mx-autoleading-losse bg-white my-6">
              <Top5Recipes />
              {/* <SubscribeSide /> */}
              {/* <Top5Posts /> */}
            </aside>
          </div>
        </InstantSearch>
      </Wrapper>
    </Layout>
  );
};

export default Advises;

const Wrapper = styled.div`
  h3 {
    margin: 0;
  }
  .ais-Hits-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .ais-Hits-item{ 
    width: 98%;
    @media (min-width: 1280px) {
      width: 48%;
    }
  }
  .ais-RefinementList-list {
    display: flex;
    gap: 0.5rem;
    justify-items: space-around;
    align-items: center;
    flex-wrap: wrap;
    
  }
  .ais-RefinementList-item {
    margin: 0;
    border-radius: 0.9rem;
    border: 1px solid rgba(138, 4, 13, 1);
    padding: 2px 8px;
    transition: all 0.5s;
    :hover,
    &.ais-RefinementList-item--selected {
      background: rgba(138, 4, 13, 1);
      color: white;
    }
  }

  .ais-ToggleRefinement-count,
  .ais-HierarchicalMenu-count,
  .ais-Menu-count,
  .ais-RatingMenu-count,
  .ais-RefinementList-count {
    border: 1px solid rgba(138, 4, 13, 0.3);
    color: rgba(138, 4, 13, 0.3);
  }

  .ais-RefinementList-showMore {
    border: 1px solid rgba(138, 4, 13, 1);
    color: rgba(138, 4, 13, 0.7);
    margin-top: 1rem;
    :focus {
      border-color: rgba(138, 4, 13, 1);
    box-shadow: rgba(138, 4, 13, 1); 0 0 0 1px, rgb(35 38 59 / 5%) 0 2px 0 1px;
    }
  }

  .ais-RefinementList-item--selected,
  .ais-RefinementList-checkbox,
  .ais-GeoSearch-input:checked {
    color: rgba(138, 4, 13, var(--tw-text-opacity));
  }
`;
